import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/import';

export default {
    flat_import(file) {
        const formData = new FormData()
        formData.append('file', file);
        return axios.post(`${RESOURCE_NAME}/flats-import`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    get_config() {
        return axios.get(`${RESOURCE_NAME}/payment-import-config`)
    },
    save_or_update_payment_import_config(data) {
        return axios.post(`${RESOURCE_NAME}/payment-import-config`, data)
    },
    delete_payment_import_config(data) {
        return axios.post(`${RESOURCE_NAME}/payment-import-config/remove`, data)
    },
    upload_payment_file(payload) {
        return axios.post(`${RESOURCE_NAME}/payment-import-file`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    upload_eps_base_import_file(payload) {
        return axios.post(`${RESOURCE_NAME}/eps-base-import-file`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
};